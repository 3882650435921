import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { polyfill } from 'seamless-scroll-polyfill';
import { useAuth } from '../../../../contexts/Auth';
import { searchSponsors as searchSponsorsApi, trackViews } from '../../../../services/app/api';
import {
    setAppliedOpportunityFilters,
    setGoToPage,
    setKeyWordFilter,
    setOpportunityStatus,
    setSelectOpportunityType,
    setStoredOpportunityData,
} from '../../../../store/searchStudentSlice';
import { applyFilter, getOpportunityListSearchParams } from '../../../../utils/GenericFunctions';
import { INITIAL_SELECTED_INPUTS, Roles } from '../../../../utils/GetConstants';
import { EmptySearchResult } from '../../../Common/EmptySeachResult';
import BlockingLoader from '../../../UI/BlockingLoader';
import Button from '../../../UI/Button';
import { SearchBox } from './SearchBox';
import { SearchPagination } from './SearchPagination';
import { StudentSearchCard } from './StudentSearchCard';

polyfill();

function StudentSearch() {
    const { getRole, getSubRole, getUserName, isAuthenticated, getProfileId } = useAuth();
    const [sponsorsData, setSponsorsData] = useState([]);
    const [allSponsorData, setAllSponsorData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const storedOpportunityData = useSelector((state) => state.searchStudentSlice.storedOpportunityData);
    const keySearch = useSelector((state) => state.searchStudentSlice.keyWordFilter);
    const goToPage = useSelector((state) => state.searchStudentSlice.page);
    const [totalPages, setTotalPages] = useState(Math.ceil(storedOpportunityData?.data.length / 10));
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!!location.state?.goToOpportunity) {
            dispatch(setOpportunityStatus(false));
            dispatch(setSelectOpportunityType(null));
            dispatch(setKeyWordFilter([]));
            dispatch(setAppliedOpportunityFilters(INITIAL_SELECTED_INPUTS));
            callSponsorsApi();
        }
        if (storedOpportunityData.data.length > 0) {
            const startIndex = (goToPage - 1) * 10;
            const endIndex = startIndex + 10;
            if (keySearch.length > 0) {
                filterOpportunityByKey(keySearch, goToPage, startIndex, endIndex);
            } else {
                setSponsorsData(storedOpportunityData.data.slice(startIndex, endIndex));
                setAllSponsorData(storedOpportunityData.data);
            }
            setIsLoading(false);
        } else {
            callSponsorsApi();
        }

        const data = {
            profile_id: null,
            viewer_profile_id: getProfileId() ? getProfileId() : 'Anonymous',
            page_type: 'opp_page',
        };
        trackViews({ data })
            .then(() => {})
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (!!location.state?.goToOpportunity) {
            const card = document.getElementById(location.state?.goToOpportunity);
            window.scrollTo({
                top: card?.offsetTop + card?.offsetHeight,
                behavior: 'smooth',
            });
        }
    }, [sponsorsData, location.state]);

    useEffect(() => {
        const titleValue = location.state?.opportunitiesTitle;
        const oppId = location.state?.goToOpportunity;
        if (storedOpportunityData.data.length > 0 && !!titleValue) {
            if (titleValue) {
                dispatch(setKeyWordFilter([titleValue]));
            }
            const startIndex = (goToPage - 1) * 10;
            const endIndex = startIndex + 10;
            filterOpportunityByKey([oppId], goToPage, startIndex, endIndex);
        }
    }, [storedOpportunityData.data]);

    async function callSponsorsApi({ filters, page = 1, status = false } = {}) {
        setIsLoading(true);
        const oppStatus = status ? 'closed' : 'active';
        const mappedSearchParams = getOpportunityListSearchParams(filters || searchParams, Roles.Student, oppStatus);
        let params = mappedSearchParams.toString();
        if (getSubRole() === 'Leader') {
            params += '&opp_for=team';
        }

        try {
            const response = await searchSponsorsApi({
                params,
                page,
                pageSize: -1,
            });
            const sponsorsData = response.data;
            dispatch(setGoToPage(1));
            setSponsorsData(sponsorsData.data.slice(0, 10));
            setAllSponsorData(sponsorsData.data);
            setTotalPages(Math.ceil(sponsorsData.data.length / 10));
            dispatch(setStoredOpportunityData(sponsorsData));
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    }

    const handleAppliedToOpportunity = (oppId) => {
        setSponsorsData((initial) =>
            initial.map((item) => (item?.id === oppId ? { ...item, is_applied: true } : item))
        );
        setAllSponsorData((initial) =>
            initial.map((item) => (item?.id === oppId ? { ...item, is_applied: true } : item))
        );
        let storedData = {
            ...storedOpportunityData,
            data: storedOpportunityData.data?.map((item) =>
                item?.id === oppId ? { ...item, is_applied: true } : item
            ),
        };
        dispatch(setStoredOpportunityData(storedData));
    };

    function onPageChange(event, newValue) {
        dispatch(setGoToPage(newValue));
        const startIndex = (newValue - 1) * 10;
        const endIndex = startIndex + 10;
        setSponsorsData(allSponsorData.slice(startIndex, endIndex));
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }
    const filterOpportunityByKey = (searchArray, page = 1, startIndex = 0, endIndex = 10) => {
        dispatch(setGoToPage(page));
        if (searchArray.length === 0) {
            setTotalPages(Math.ceil(storedOpportunityData.data.length / 10));
            setSponsorsData(storedOpportunityData.data.slice(0, 10));
            setAllSponsorData(storedOpportunityData.data);
        } else {
            const filteredArray = applyFilter(storedOpportunityData.data, searchArray);
            setTotalPages(Math.ceil(filteredArray.length / 10));
            setSponsorsData(filteredArray.slice(startIndex, endIndex));
            setAllSponsorData(filteredArray);
        }
    };

    function renderBack() {
        if (!isAuthenticated()) {
            return null;
        }

        return (
            <Button onClick={() => navigate(-1)}>
                <span className="material-icons material-icons-outlined mr-2">west</span> Back
            </Button>
        );
    }

    const areSponsorsListEmpty = sponsorsData.length === 0;
    const shouldShowEmptySearchResult = areSponsorsListEmpty && !isLoading;

    return (
        <div className="bg-[#F8F8F8] pt-10 pb-20">
            <main className="px-3 md:px-8 lg:max-w-6xl xl:max-w-6xl 2xl:max-w-5xl mx-auto pt-4">
                <div className="mb-8">{renderBack()}</div>
                <SearchBox callSponsorsApi={callSponsorsApi} filterOpportunityByKey={filterOpportunityByKey} />
                {shouldShowEmptySearchResult ? <EmptySearchResult variant="red" /> : null}
                <div className="relative">
                    <BlockingLoader loading={isLoading} />
                    {sponsorsData.map((sponsor) => {
                        return (
                            <div id={sponsor.id} key={sponsor.id}>
                                <StudentSearchCard
                                    isLoading={isLoading}
                                    handleAppliedToOpportunity={handleAppliedToOpportunity}
                                    searchParams={searchParams}
                                    {...sponsor}
                                />
                            </div>
                        );
                    })}
                    <SearchPagination page={goToPage} totalPages={totalPages} onPageChange={onPageChange} />
                </div>
            </main>
        </div>
    );
}

export default StudentSearch;
